import { financeErrorValidator } from "@app/pages/financials/shared/const/finance-error-message.const";

export const ROAM_VALIDATORS: any = {
  ...financeErrorValidator
}


export const ROAM_MESSAGES = {
  savedData: 'Data has been saved',
  error: 'An error occurred while saving the data. Please try again.'
}